import { Head } from 'components/common';
import { Contact, Intro } from 'components/contact';
import ContactLayout from 'layout/contact-layout';
import { useTranslation } from 'react-i18next';

const ContactAdvertiserPage = () => {
  const { t } = useTranslation();
  return (
    <ContactLayout>
      <Head title="Contact" description={t('contacts.intro.description')} />
      <Intro />
      <Contact />
    </ContactLayout>
  );
};

export default ContactAdvertiserPage;
