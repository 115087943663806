import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const ContactIntro = () => {
  const { t } = useTranslation();

  return (
    <div className={cn('flex flex-col items-center justify-center', 'lg:pt-[180px]', 'pt-[124px]')}>
      <h1
        className={cn(
          'font-bold text-[#212529] leading-[124%] whitespace-pre-line text-center',
          'lg:text-[50px] lg:mb-[14px]',
          'text-[32px] mb-[10px] px-[20px]',
        )}
      >
        {t('contacts.intro.title')}
      </h1>
      <p
        className={cn(
          'tracking-[-0.3px] leading-[160%] font-normal text-[#808C99] whitespace-pre-line text-center',
          'lg:text-[18px] lg:mb-[30px]',
          'text-[16px] mb-[20px] px-[20px]',
        )}
      >
        {t('contacts.intro.description')}
      </p>
    </div>
  );
};

export default ContactIntro;
