import { Footer, Header } from 'components/common';
import { ReactNode } from 'react';

interface ContactLayoutProps {
  children: ReactNode;
}

const ContactLayout = ({ children }: ContactLayoutProps) => {
  return (
    <>
      <Header />
      <main>
        <div id="advertisers" />
        <div id="publishers" />
        {children}
      </main>
      <Footer />
    </>
  );
};

export default ContactLayout;
