import { FormikErrors, FormikTouched } from 'formik';

export const shouldErrorShows = <V extends Record<string, unknown>>(
  fieldName: keyof V,
  touched: FormikTouched<V>,
  errors: FormikErrors<V>,
) => {
  return Boolean(touched[fieldName] && errors[fieldName]);
};

export const getHelperText = <V extends Record<string, unknown>>(
  fieldName: keyof V,
  touched: FormikTouched<V>,
  errors: FormikErrors<V>,
  tip?: string,
) => {
  return shouldErrorShows(fieldName, touched, errors) ? errors[fieldName] : tip;
};

export const REG_EXP = {
  /** 이메일 정규표현식 */
  email:
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
  /** 휴대전화 정규표현식 */
  phone: /^(0?((1[0|1|6|7|8|9]{1})|2|([3|4|5|6]([1|6|7|8|9]{1}))))-{0,1}(\d{3,4})-{0,1}(\d{4})$/,
  /** 도메인 정규표현식 */
  domain:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
};
