import { useLocation } from '@reach/router';
import { Tab, Tabs } from 'components/common';
import { navigate } from 'gatsby';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdvertiserForm from './advertiser-form';
import PublisherForm from './publisher-form';

const ContactForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [hash, setHash] = useState('#advertisers');

  useEffect(() => {
    if (location.hash) setHash(location.hash);
  }, [location.hash]);

  const onChangeTab = useCallback((v: string) => {
    setHash(v);
    navigate(`/contact${v}`, { replace: true });
  }, []);

  return (
    <div className="flex flex-col justify-center items-center mb-[30px]">
      <div className="flex items-center justify-center mb-[30px]">
        <Tabs value={hash} onChange={onChangeTab}>
          <Tab value="#advertisers">{t('contacts.tabs.advertiser')}</Tab>
          <Tab value="#publishers">{t('contacts.tabs.publisher')}</Tab>
        </Tabs>
      </div>
      {hash === '#advertisers' && <AdvertiserForm />}
      {hash === '#publishers' && <PublisherForm />}
    </div>
  );
};

export default ContactForm;
