import emailjs from '@emailjs/browser';
import cn from 'classnames';
import { Checkbox, Option, Select, Textarea, TextField } from 'components/common';
import {
  AD_INTEREST_OPTIONS,
  BUDGET_OPTIONS,
  COUNTRY_OPTIONS,
  DEFAULT_BUDGET,
  DEFAULT_COUNTRY,
  DEFAULT_JOB,
  JOB_OPTIONS,
} from 'components/contact/constant';
import { useFormik } from 'formik';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import useToast from 'hooks/useToast';
import i18next from 'lang/i18n';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getHelperText, REG_EXP } from 'utils/form-helper';
import * as yup from 'yup';

const AdvertiserForm = () => {
  const toast = useToast();
  const { t } = useTranslation();

  const {
    site: {
      siteMetadata: {
        contact: { publicKey, serviceID, adTemplateID },
      },
    },
  } = useStaticQuery(
    graphql`
      query AdvertiserFormQuery {
        site {
          siteMetadata {
            contact {
              publicKey
              serviceID
              adTemplateID
            }
          }
        }
      }
    `,
  );

  const PrivacyLabel = useMemo(() => {
    if (i18next.language === 'en') {
      return t('contacts.form.privacy.label');
    }
    const label = t('contacts.form.privacy.label');
    return (
      <span>
        {label.slice(0, 5)}
        <a className="underline" target="_blank" rel="noopener noreferrer" href="/policy/privacy">
          {label.slice(5, 17)}
        </a>
        {label.slice(17)}
      </span>
    );
  }, [i18next.language]);

  const initialValues = useMemo(
    () => ({
      country: DEFAULT_COUNTRY,
      name: '',
      email: '',
      phone: '',
      company: '',
      site: '',
      job: DEFAULT_JOB,
      budget: DEFAULT_BUDGET,
      adInterest: '',
      message: '',
      privacy: false,
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        country: yup.string().required(t('contacts.form.country.placeholder')),
        name: yup.string().required(t('contacts.form.name.placeholder')),
        email: yup
          .string()
          .matches(REG_EXP.email, t('contacts.form.email.placeholder'))
          .required(t('contacts.form.email.placeholder')),
        phone: yup
          .string()
          .matches(REG_EXP.phone, t('contacts.form.phone.placeholder'))
          .required(t('contacts.form.phone.placeholder')),
        company: yup.string().required(t('contacts.form.company.placeholder')),
        site: yup.string().matches(REG_EXP.domain, t('contacts.form.site.placeholder')),
        job: yup.string(),
        budget: yup.string(),
        adInterest: yup.string(),
        message: yup.string().required(t('contacts.form.message.placeholder')),
        privacy: yup
          .boolean()
          .isTrue(t('contacts.form.privacy.placeholder'))
          .required(t('contacts.form.privacy.placeholder')),
      }),
    [t, yup],
  );

  const { getFieldProps, setFieldValue, handleSubmit, touched, errors, isSubmitting } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      emailjs.send(serviceID, adTemplateID, values, publicKey).then(
        (_response) => {
          toast.success(t('contacts.form.submit.success'));
          resetForm();
          navigate('/');
        },
        (_err) => {
          toast.error(t('contacts.form.submit.fail'));
        },
      );
    },
  });

  const onSelect = useCallback(
    (field: string) => (v: string) => {
      setFieldValue(field, v);
    },
    [setFieldValue],
  );

  return (
    <form
      className={cn(
        'w-full grid rounded-[12px] shadow-[0px_6px_20px_rgba(16,24,40,0.04)] p-[30px]',
        'lg:max-w-[786px] lg:grid-cols-2 lg:gap-x-[30px] lg:gap-y-[20px]',
        'grid-cols-1 gap-y-[18px]',
      )}
      onSubmit={handleSubmit}
    >
      <div className={cn('lg:col-span-1', 'col-span-2')}>
        <Select
          required
          label={t('contacts.form.country.label')}
          placeholder={t('contacts.form.country.placeholder')}
          helperText={getHelperText('country', touched, errors)}
          {...getFieldProps('country')}
          onSelect={onSelect('country')}
        >
          {COUNTRY_OPTIONS.map((option) => (
            <Option key={option.value} value={option.value}>
              {t(option.tKey)}
            </Option>
          ))}
        </Select>
      </div>
      <div className={cn('lg:col-span-1', 'col-span-2')}>
        <TextField
          required
          label={t('contacts.form.name.label')}
          placeholder={t('contacts.form.name.placeholder')}
          helperText={getHelperText('name', touched, errors)}
          {...getFieldProps('name')}
        />
      </div>
      <div className={cn('lg:col-span-1', 'col-span-2')}>
        <TextField
          required
          type="email"
          label={t('contacts.form.email.label')}
          placeholder={t('contacts.form.email.placeholder')}
          helperText={getHelperText('email', touched, errors)}
          {...getFieldProps('email')}
        />
      </div>
      <div className={cn('lg:col-span-1', 'col-span-2')}>
        <TextField
          required
          type="tel"
          label={t('contacts.form.phone.label')}
          placeholder={t('contacts.form.phone.placeholder')}
          helperText={getHelperText('phone', touched, errors)}
          {...getFieldProps('phone')}
        />
      </div>
      <div className={cn('lg:col-span-1', 'col-span-2')}>
        <TextField
          required
          label={t('contacts.form.company.label')}
          placeholder={t('contacts.form.company.placeholder')}
          helperText={getHelperText('company', touched, errors)}
          {...getFieldProps('company')}
        />
      </div>
      <div className={cn('lg:col-span-1', 'col-span-2')}>
        <TextField
          label={t('contacts.form.site.label')}
          placeholder={t('contacts.form.site.placeholder')}
          helperText={getHelperText('site', touched, errors)}
          {...getFieldProps('site')}
        />
      </div>
      <div className={cn('lg:col-span-1', 'col-span-2')}>
        <Select
          required
          label={t('contacts.form.job.label')}
          placeholder={t('contacts.form.job.placeholder')}
          helperText={getHelperText('job', touched, errors)}
          {...getFieldProps('job')}
          onSelect={onSelect('job')}
        >
          {JOB_OPTIONS.map((option) => (
            <Option key={option.value} value={option.value}>
              {t(option.tKey)}
            </Option>
          ))}
        </Select>
      </div>
      <div className={cn('lg:col-span-1', 'col-span-2')}>
        <Select
          required
          label={t('contacts.form.budget.label')}
          placeholder={t('contacts.form.budget.placeholder')}
          helperText={getHelperText('budget', touched, errors)}
          {...getFieldProps('budget')}
          onSelect={onSelect('budget')}
        >
          {BUDGET_OPTIONS.map((option) => (
            <Option key={option.value} value={option.value}>
              {t(option.tKey)}
            </Option>
          ))}
        </Select>
      </div>
      <div className="col-span-2">
        <Select
          label={t('contacts.form.adInterest.label')}
          placeholder={t('contacts.form.adInterest.placeholder')}
          helperText={getHelperText('adInterest', touched, errors)}
          {...getFieldProps('adInterest')}
          onSelect={onSelect('adInterest')}
        >
          {AD_INTEREST_OPTIONS.map((option) => (
            <Option key={option.value} value={option.value}>
              {t(option.tKey)}
            </Option>
          ))}
        </Select>
      </div>
      <div className="col-span-2">
        <Textarea
          className="resize-none min-h-[200px]"
          required
          label={t('contacts.form.message.label')}
          placeholder={t('contacts.form.message.placeholder')}
          helperText={getHelperText('message', touched, errors)}
          {...getFieldProps('message')}
        />
      </div>
      <div
        className={cn(
          'col-span-2 flex justify-between',
          'lg:flex-row lg:gap-0',
          'flex-col gap-[20px]',
        )}
      >
        <Checkbox
          label={PrivacyLabel}
          helperText={getHelperText('privacy', touched, errors)}
          {...getFieldProps('privacy')}
        />
        <button
          type="submit"
          disabled={isSubmitting}
          className={cn(
            'flex justify-center items-center text-white tracking-[-0.3px] bg-[#2685F4] px-[20px] rounded-[6px] hover:bg-[#2685F4]/80 focus:outline-none disabled:cursor-not-allowed disabled:text-[#bfbfbf] disabled:bg-[#f5f5f5]',
            'lg:flex-initial lg:py-[12px]',
            'flex-1 py-[14px]',
          )}
        >
          {t('contacts.form.submit.label')}
        </button>
      </div>
    </form>
  );
};

export default AdvertiserForm;
