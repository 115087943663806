// default value
export const DEFAULT_COUNTRY = '대한민국';
export const DEFAULT_JOB = '일반 광고주';
export const DEFAULT_BUDGET = '100만원 미만';
export const DEFAULT_DAU = '1만 명 미만';
export const DEFAULT_REWARD_INTEREST = '수익화 및 미디에이션';
export const DEFAULT_CATEGORY = '게임';
export const DEFAULT_USE_OF_REWARD_AD_PLUS = '사용 요청하지 않음';

// common
export const COUNTRY_OPTIONS = [
  { value: DEFAULT_COUNTRY, tKey: 'contacts.form.country.options.option1' },
  { value: '아시아', tKey: 'contacts.form.country.options.option2' },
  { value: '유럽', tKey: 'contacts.form.country.options.option3' },
  { value: '남아메리카', tKey: 'contacts.form.country.options.option4' },
  { value: '북아메리카', tKey: 'contacts.form.country.options.option5' },
  { value: '오세아니아', tKey: 'contacts.form.country.options.option6' },
  { value: '아프리카', tKey: 'contacts.form.country.options.option7' },
  { value: '남극', tKey: 'contacts.form.country.options.option8' },
];

// advertiser

export const JOB_OPTIONS = [
  { value: DEFAULT_JOB, tKey: 'contacts.form.job.options.option1' },
  { value: '브랜드 마케터', tKey: 'contacts.form.job.options.option2' },
  { value: '퍼포먼스 마케터', tKey: 'contacts.form.job.options.option3' },
  { value: 'SNS 마케터', tKey: 'contacts.form.job.options.option4' },
  { value: '마케팅 에이젼시', tKey: 'contacts.form.job.options.option5' },
];

export const BUDGET_OPTIONS = [
  { value: DEFAULT_BUDGET, tKey: 'contacts.form.budget.options.option1' },
  { value: '100만원 이상 500만원 이하', tKey: 'contacts.form.budget.options.option2' },
  { value: '500만원 이상 1,000만원 이하', tKey: 'contacts.form.budget.options.option3' },
  { value: '1,000만원 이상', tKey: 'contacts.form.budget.options.option4' },
];

export const AD_INTEREST_OPTIONS = [
  { value: '신규 유저 확보', tKey: 'contacts.form.adInterest.options.option1' },
  { value: '브랜드 인지도 강화', tKey: 'contacts.form.adInterest.options.option2' },
  { value: '웹 사이트 유입', tKey: 'contacts.form.adInterest.options.option3' },
  {
    value: '전환(제품에 대한 판매 혹은 서비스 구독)',
    tKey: 'contacts.form.adInterest.options.option4',
  },
  { value: '기타', tKey: 'contacts.form.adInterest.options.option5' },
];

// publisher
export const DAU_OPTIONS = [
  { value: '1만 명 미만', tKey: 'contacts.form.dau.options.option1' }, // default
  { value: '1만 명 이상 10만 명 이하', tKey: 'contacts.form.dau.options.option2' },
  { value: '10만 명 이상', tKey: 'contacts.form.dau.options.option3' },
];

export const REWARD_INTEREST_OPTIONS = [
  { value: DEFAULT_REWARD_INTEREST, tKey: 'contacts.form.rewardInterest.options.option1' }, // default
  { value: '오퍼월', tKey: 'contacts.form.rewardInterest.options.option2' },
  { value: '앱 광고 운영', tKey: 'contacts.form.rewardInterest.options.option3' },
  { value: '기타', tKey: 'contacts.form.rewardInterest.options.option4' },
];

export const CATEGORY_OPTIONS = [
  { value: DEFAULT_CATEGORY, key: 'contacts.form.category.options.option1' },
  { value: '비게임', key: 'contacts.form.category.options.option2' },
];

export const USE_OF_REWARD_AD_PLUS_OPTIONS = [
  { value: DEFAULT_USE_OF_REWARD_AD_PLUS, key: 'contacts.form.useOfRewardAdPlus.options.option1' },
  { value: '사용 요청', key: 'contacts.form.useOfRewardAdPlus.options.option2' },
];
